import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/helper';
import { PRIVACY_POLICY_LINK } from '../../constants';

const CookieNotice = () => {
  const [showMe, setShowMe] = useState(() => localStorage.getItem('CookieAccepted') !== 'YES');

  const confirmCookie = () => {
    localStorage.setItem('CookieAccepted', 'YES');
    setShowMe(false);
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (showMe) {
      html.classList.add('is-cookie-notice-opened', 'is-cookie-notice-minimal');
    }

    return () => {
      html.classList.remove('is-cookie-notice-opened', 'is-cookie-notice-minimal');
    };
  }, [showMe]);

  // console.log('SHOWme', this.state.showMe, localStorage.getItem('CookieAccepted'));
  return (
    <React.Fragment>
      {showMe && (
        <div className='c-cookie-notice'>
          <p>{i18n.t('cookies.notice')}</p>
          <a
            className='c-button  c-button--outline'
            href={PRIVACY_POLICY_LINK}
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='c-button__label'>{i18n.t('dashboard.settings.policy')}</span>
          </a>
          <button className='c-button c-button--primary' onClick={confirmCookie}>
            <span className='c-button__label'>{i18n.t('cookies.accept')}</span>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CookieNotice;
