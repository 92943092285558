import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PillButton from '../components/button/PillButton';

import { getNews as getNewsAction, clearNewsData as clearNewsDataAction } from '../actions';
import { Img, WIDE } from '../components/images/Img';
import i18n from '../i18n/helper';
import MarkdownField, { MARKDOWN_MAX, MARKDOWN_MIN_LINK } from '../components/markdown/MarkdownField';
import Spinner from '../components/spinner/spinner';
import ShareSocial from '../components/share/ShareSocial';
import SORTING from '../shared/engage-notifications-enums';

const SORTING_ENUM = SORTING().EngageNotificationSortType;

function NewsDetails(props) {
  const { location, newsData, slugId, actions } = props;
  const { getNews, clearNewsData } = actions;
  // const { title, description, resource, newsContent, actionName, actionUrl } = location.state.news;
  // const date = location.state.date;
  const newsId = location.pathname.split('/')[4];
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(newsData.isLoading);

  useEffect(() => {
    getNews(slugId, SORTING_ENUM.TOP);
    return () => {
      const data = {
        isNewsDataPresent: false,
        renderWayPoint: false,
        page: 0,
        newsData: [],
        isLoading: true,
      };
      clearNewsData(data);
    };
  }, [getNews, slugId, clearNewsData]);

  useEffect(() => {
    setNews(newsData.newsData.find((item) => item._id === newsId));
    setIsLoading(newsData.isLoading);
  }, [newsData, newsId]);

  const actionNameTrimmed = news && news.actionName ? news.actionName.trim() : null;
  const actionUrlTrimmed = news && news.actionUrl ? news.actionUrl.trim() : null;

  const shareURL = window.location.origin + location.pathname;

  return (
    <main className='l-section-main c-section-main' id='appContent'>
      <div className='l-container'>
        {isLoading === true ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className='c-section-intro'>
              <h1 className='c-section-intro__title'>{i18n.t('header.news')}</h1>
              <div className='c-section-intro__action'>
                <PillButton
                  url='news'
                  classModifier='c-button--small c-button--alternate'
                  icon={false}
                  title={i18n.t('news.all')}
                />
              </div>
            </div>
            <article className='c-news'>
              <div className='c-news__header'>
                <h1 className='c-news__title'>{news.title}</h1>
                <p className='c-news__meta'>
                  <span className='c-news__meta-date'>{moment(news.timestamp).format('DD.MM.YYYY.')}</span>
                </p>
              </div>
              <div className='c-news__summary u-rich-text'>
                <p>
                  <MarkdownField markdown={news.description} markdownConfig={MARKDOWN_MIN_LINK} />
                </p>
              </div>
              {news.resource && (
                <figure className='c-news__figure'>
                  <Img imageType={WIDE} imageId={news.resource} alt='' />
                </figure>
              )}
              <div className='c-news__content u-rich-text'>
                <MarkdownField markdown={news.newsContent} markdownConfig={MARKDOWN_MAX} />
              </div>

              <div className='c-news__action'>
                {actionUrlTrimmed && actionNameTrimmed && (
                  <PillButton
                    url={actionUrlTrimmed}
                    title={actionNameTrimmed}
                    classModifier='c-button--large c-button--alternate c-button--block'
                  />
                )}
                <ShareSocial slugId={slugId} shareURL={shareURL} classModifier='c-social--large' />
              </div>
            </article>
          </React.Fragment>
        )}
      </div>
    </main>
  );
}

NewsDetails.propTypes = {
  location: PropTypes.object.isRequired,
  newsData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  newsData: state.news,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getNews: getNewsAction, clearNewsData: clearNewsDataAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
