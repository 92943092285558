import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import ReactPixel from 'react-facebook-pixel';
import smoothscroll from 'smoothscroll-polyfill';

import AnnotateTooltip from 'components/organisms/questions/annotateAdvanced/AnnotateTooltip/AnnotateTooltip';
import ProjectHome from './containers/ProjectHome';
import Footer from './components/footer/Footer';
import Timeline from './containers/Timeline';
import Home from './containers/Home';
import Polls from './containers/Polls';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import News from './containers/News';
import More from './containers/More';
import Share from './containers/More/Share';
import Faq from './containers/More/Faq';
import Feedback from './containers/More/Feedback';
// import Profile from './components/profile/Profile';
import * as api from './services/api';
import PageLoader from './components/spinner/PageLoader';
import Terms from './containers/TermsContainers';
import RouteNotFound from './RouteNotFound';
// import PageNotFound from "./PageNotFound"
import * as URL from './constants.js';
import GoogleAnalytics from './GoogleAnalytics';
import CookieNotice from './components/cookieNotice/CookieNotice';
import PreviewNotice from './components/previewNotice/PreviewNotice';
import { addCustomStrings } from './i18n/helper';
import NewsDetails from './containers/NewsDetails';
import CookieControl from './components/cookieNotice/CookieControl';

const nonProjectURLs = ['terms-and-conditions', 'privacy-policy'];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      message: <PageLoader />,
      slug_id: '',
      slug: this.props.location.pathname.split('/')[1],
      isNonProjectURL: nonProjectURLs.indexOf(this.props.location.pathname.split('/')[1]) > -1,
      temp: 0,
      fromApp: false, // this state varible will become true when we change the language so we need to forceupadate every section and tell that we are again coming from App,
      foolHeader: false,
      domain: {},
      showCookieControl: false,
      showPreviewNotice: false,
    };
    this.initialQuestion = null;
    this.isPDFPreview = false;

    this.isEmbed = false;
    this.isPollsOnly = false;

    this.autoHeight = false;
    this.getTheSlugForProject = this.getTheSlugForProject.bind(this);
    // kick off the polyfill!
    smoothscroll.polyfill();
  }

  componentDidMount() {
    /**
     * FB Pixel initialization
     */
    ReactPixel.init(URL.FB_PIXEL, null, { autoConfig: true, debug: false });
    ReactPixel.trackSingle(URL.FB_PIXEL, 'PageView');

    /*
     * NEW
     * Init App state from QueryString
     * Handling question=245345&isPreview=true&lang=EN
     */
    sessionStorage.setItem('slug', this.state.slug);
    const initialLanguage = this.handleQueryParams();
    this.getTheSlugForProject(initialLanguage);

    ((doc, script) => {
      const element = doc.getElementsByTagName(script)[0];
      const fjs = element;
      const js = doc.createElement(script);
      js.src = `https://www.googletagmanager.com/gtag/js?id=${URL.GAKEY}`;
      js.defer = true;
      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      } else {
        doc.head.appendChild(js);
      }
    })(document, 'script');
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${URL.GAKEY}`;
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', URL.GAKEY, { cookie_domain: 'none' });

    /**
     * google recaptcha script
     */
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${URL.RECAPTCHA}`;
    recaptchaScript.async = true;
    document.body.appendChild(recaptchaScript);
  }

  /*
   * Init App state from QueryString
   * Handling question=245345&isPreview=true&lang=EN
   * Returns initialLanguage if specified - null otherwise
   */
  handleQueryParams = () => {
    const parsed = qs.parse(this.props.location.search);
    // console.log('PARSED', parsed);

    if (parsed.embed) {
      this.isEmbed = true;
    }

    if (parsed.autoheight) {
      this.autoHeight = true;
    }

    // This flag has functional impact on different sub components and API calls
    if (parsed.isPreview) {
      sessionStorage.setItem('isPreview', parsed.isPreview);
      this.setState({ showPreviewNotice: true });
    }
    // This flag only provides styling hook specific to PDF preview
    if (parsed.isPDFPreview) {
      this.isPDFPreview = true;
    }
    // Display/open this particular question (for preview feature)
    if (parsed.question) {
      this.initialQuestion = parsed.question;
    }
    // Language is permanently set (also for subsequent page landing)
    // Used mainly to directly set the language from a referral link (think ad in Tukish)
    // hence setting in localStorage
    if (parsed.lang) {
      localStorage.setItem('lang', parsed.lang);
      return parsed.lang;
    }
    return null;
  };

  getTheSlugForProject(initialLanguage) {
    const endpoint = `/projects/${this.state.slug}/identifier`;
    api
      .get(endpoint)
      .then((res) => {
        if (res.data.canonicSlug) {
          const urlWithSlug = `${this.props.location.pathname.split('/')[0]}/${res.data.canonicSlug}`;
          window.open(urlWithSlug, '_self');
        }

        // now string customizations are embedded in /identifier request
        // this way I save 1 round trip at app opening (0.3s on good network)
        addCustomStrings(res.data.stringCustomization, initialLanguage);

        this.isPollsOnly = res.data.isPollsOnlyProject;

        sessionStorage.setItem('slug_id', res.data._id);
        this.setState({
          isLoading: false,
          slug_id: res.data._id,
          domain: res.data.domain,
        });
        return res.data._id;
      })
      .catch(() => {
        this.setState({
          isLoading: true,
          message: <Home />,
        });
      });
  }

  /**
   * Handle styling for preview(s)
   */
  getClassModifier() {
    let classNameModifier = '';
    if (sessionStorage.getItem('isPreview')) {
      if (this.isPDFPreview) {
        classNameModifier += ' l-app--pdf';
      } else {
        classNameModifier += ' l-app--preview';
      }
    }
    if (this.isEmbed) {
      classNameModifier += ' l-app--embed';
    }
    if (this.autoHeight) {
      classNameModifier += ' l-app--embed-auto';
    }
    if (!this.props.location.pathname.split('/')[2]) {
      classNameModifier += ' l-app--blocks';
    }
    if (this.isPollsOnly) {
      classNameModifier += ' l-app--polls-only';
    }
    return classNameModifier;
  }

  pageNotFound = () => {
    this.setState({
      isLoading: true,
      message: <Home />,
    });
  };

  forceUpdateHandler = () => {
    // this.forceUpdate();
    this.setState((p) => ({
      temp: p.temp + 1,
      fromApp: true,
      foolHeader: !p.foolHeader,
    }));
  };

  /**
   * show Preview Notice
   */
  previewNoticeControl = () => {
    this.setState({ showPreviewNotice: !this.state.showPreviewNotice });
  };

  /**
   * show Cookie Control
   */
  cookieControl = () => {
    this.setState({ showCookieControl: !this.state.showCookieControl });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isNonProjectURL && (
          <React.Fragment>
            <Route exact path='/terms-and-conditions' component={Terms} />
            <Route exact path='/privacy-policy' component={Terms} />
          </React.Fragment>
        )}
        {this.state.isLoading && !this.state.isNonProjectURL && this.state.message}
        {!this.state.isLoading && !this.state.isNonProjectURL && (
          <div className={`l-app ${this.getClassModifier()}`}>
            <div className='c-skip-links'>
              <a href='#appNav' className='c-skip-links__link'>
                Jump to <strong>navigation</strong>
              </a>
              <a href='#appContent' className='c-skip-links__link'>
                Jump to <strong>content</strong>
              </a>
            </div>
            <Header
              location={this.props.location}
              slug_id={this.state.slug_id}
              forceUpdateHandler={this.forceUpdateHandler}
              foolHeader={this.state.foolHeader}
            />
            {!this.isPollsOnly ? <Navbar foolHeader={this.state.foolHeader} slug_id={this.state.slug_id} /> : null}
            {!this.isPollsOnly ? (
              <Switch>
                <Route
                  exact
                  path={`/${this.state.slug}`}
                  component={(props) => <ProjectHome {...props} slug_id={this.state.slug_id} />}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/timeline`}
                  component={(props) => <Timeline {...props} slugId={this.state.slug_id} />}
                />
                <Redirect exact from={`/${this.state.slug}/polls/:id?`} to={`/${this.state.slug}/surveys/:id?`} />
                <Route
                  exact
                  path={`/${this.state.slug}/surveys/:id?`}
                  component={(props) => (
                    <Polls
                      {...props}
                      slugId={this.state.slug_id}
                      domain={this.state.domain}
                      initialQuestion={this.initialQuestion}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/news`}
                  component={(props) => <News {...props} slugId={this.state.slug_id} fromApp={this.state.fromApp} />}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/more/:section?`}
                  component={(props) => <More {...props} slug_id={this.state.slug_id} />}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/share`}
                  component={(props) => (
                    <Share
                      {...props}
                      slugId={this.state.slug_id}
                      shareURL={`${process.env.REACT_APP_OG_URL}${this.state.slug}`}
                    />
                  )}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/faq`}
                  component={(props) => <Faq {...props} slugId={this.state.slug_id} />}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/news/:title/:id`}
                  component={(props) => <NewsDetails {...props} slugId={this.state.slug_id} />}
                />
                <Route
                  exact
                  path={`/${this.state.slug}/feedback`}
                  component={(props) => <Feedback {...props} slugId={this.state.slug_id} />}
                />
                {/* <Route exact path='/home' component={props =>(<Home {...props} />)} /> */}

                <Route component={() => <RouteNotFound redirectTo={this.pageNotFound} />} />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  path={`/${this.state.slug}/surveys/:id?`}
                  component={(props) => (
                    <Polls
                      {...props}
                      slugId={this.state.slug_id}
                      domain={this.state.domain}
                      initialQuestion={this.initialQuestion}
                    />
                  )}
                />
                <Redirect exact from={`/${this.state.slug}/polls/:id?`} to={`/${this.state.slug}/surveys/:id?`} />
                <Route
                  exact
                  path={`/${this.state.slug}/feedback`}
                  component={(props) => <Feedback {...props} slugId={this.state.slug_id} />}
                />
                <Redirect from='*' to={`/${this.state.slug}/surveys/:id?`} />
              </Switch>
            )}
            <Footer onClick={this.cookieControl} />
            <CookieControl showCookieControl={this.state.showCookieControl} onClick={this.cookieControl} />
            <GoogleAnalytics />
            {/* <Profile slug_id={this.state.slug_id} /> */}
            {!sessionStorage.getItem('isPreview') && <CookieNotice />}
            {sessionStorage.getItem('isPreview') && this.state.showPreviewNotice && (
              <PreviewNotice removeNotice={this.previewNoticeControl} />
            )}
          </div>
        )}
        <AnnotateTooltip />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
