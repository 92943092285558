import React from 'react';

import { ReactComponent as GMVSymbol } from '../../assets-v2/images/gmv-symbol.svg';
import i18n from '../../i18n/helper';
import { TERMS_AND_CONDITIONS_LINK, PRIVACY_POLICY_LINK } from '../../constants';

export default function Footer() {
  return (
    <div className='l-m-section-footer c-m-section-footer' role='contentinfo'>
      <footer className='l-m-footer c-m-footer'>
        <div className='l-container'>
          <p className='c-m-footer__info'>
            <span className='c-m-logo c-m-logo--footer'>
              <a className='c-m-logo__link' href='/'>
                <GMVSymbol />
                Give My View
              </a>
              <a className='c-m-logo__powered' href='https://www.built-id.com/'>
                Powered by LandTech
              </a>
            </span>
          </p>

          <nav className='c-m-nav-footer'>
            <ul className='c-m-nav-footer__items'>
              <li className='c-m-nav-footer__item'>
                <a className='c-m-nav-footer__link' href={PRIVACY_POLICY_LINK} rel='noopener noreferrer'>
                  {i18n.t('dashboard.settings.policy')}
                </a>
              </li>
              <li className='c-m-nav-footer__item'>
                <a className='c-m-nav-footer__link' href={TERMS_AND_CONDITIONS_LINK} rel='noopener noreferrer'>
                  {i18n.t('dashboard.settings.terms')}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
}
