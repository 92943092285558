import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';

import SvgIcon from '../SvgIcon';
import { PRIVACY_POLICY_LINK, GAKEY } from '../../constants';
import i18n from '../../i18n/helper';

export default function CookieControl(props) {
  const { showCookieControl, onClick } = props;

  const [isAnalyticsCookie, setIsAnalyticsCookie] = useState(true);
  const [isMarketingCookie, setIsMarketingCookie] = useState(true);

  useEffect(() => {
    setIsAnalyticsCookie(localStorage.getItem('AnalyticsCookies') === 'true');
    setIsMarketingCookie(localStorage.getItem('MarketingCookies') === 'true');
  }, []);

  useEffect(() => {
    localStorage.setItem('AnalyticsCookies', isAnalyticsCookie);
    localStorage.setItem('MarketingCookies', isMarketingCookie);

    window[`ga-disable-${GAKEY}`] = isAnalyticsCookie;
    if (isMarketingCookie) {
      ReactPixel.grantConsent();
    } else {
      ReactPixel.revokeConsent();
    }
  }, [isMarketingCookie, isAnalyticsCookie]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    showCookieControl && body.classList.add('is-cookie-panel-opened');

    return () => {
      body.classList.remove('is-cookie-panel-opened');
    };
  }, [showCookieControl]);

  return (
    <div className='c-cookie-panel-container' aria-hidden={!showCookieControl}>
      <div className='c-cookie-panel' tabIndex='-1' role='dialog' aria-labelledby='cookiePanelTitle'>
        <h2 className='c-cookie-panel__title' id='cookiePanelTitle'>
          {i18n.t('cookies.ourCookies')}
        </h2>
        <div className='c-cookie-panel__description u-rich-text'>
          <p>{i18n.t('cookies.explainer')}</p>
          <p>
            {i18n.t('cookies.details')}{' '}
            <a href={PRIVACY_POLICY_LINK} target='_blank' rel='noopener noreferrer'>
              {i18n.t('dashboard.settings.policy')}
            </a>
            .
          </p>
        </div>
        <div className='l-fields c-fields'>
          <div className='c-field c-field--label-hide'>
            <label className='c-field__label' htmlFor='check-toggle'>
              {i18n.t('cookies.analytics')}
            </label>
            <div className='c-field__group'>
              <div className='o-checkbox o-checkbox--toggle'>
                <input
                  type='checkbox'
                  id='Analytics cookies'
                  name='check-toggle'
                  checked={isAnalyticsCookie}
                  onChange={() => setIsAnalyticsCookie(!isAnalyticsCookie)}
                />
                <label htmlFor='Analytics cookies'>
                  {i18n.t('cookies.analytics')}
                  <SvgIcon icon='check' />
                </label>
              </div>
              <p className='c-field__description'>{i18n.t('cookies.analyticsDetails')}</p>
            </div>
          </div>
          <div className='c-field c-field--label-hide'>
            <label className='c-field__label' htmlFor='check-toggle'>
              {i18n.t('cookies.marketing')}
            </label>
            <div className='c-field__group'>
              <div className='o-checkbox o-checkbox--toggle'>
                <input
                  type='checkbox'
                  id='Marketing cookies'
                  name='check-toggle'
                  checked={isMarketingCookie}
                  onChange={() => setIsMarketingCookie(!isMarketingCookie)}
                />
                <label htmlFor='Marketing cookies'>
                  {i18n.t('cookies.marketing')}
                  <SvgIcon icon='check' />
                </label>
              </div>
              <p className='c-field__description'>{i18n.t('cookies.marketingDetails')}</p>
            </div>
          </div>
          <div className='c-field'>
            <div className='c-field__group'>
              <button className='c-button c-button--primary' onClick={onClick}>
                <span className='c-button__label'>{i18n.t('cookies.saveAndClose')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
