import { useCallback, useEffect, useRef } from 'react';
import GOOGLE_MAP_ENUMS from 'shared/engage-google-map-enums';
import { QuestionMapSchema } from '../../types';

// const { shapeTypes } = GOOGLE_MAP_ENUMS();
const roadmapOverlayStyle = {
  ...GOOGLE_MAP_ENUMS().roadmapOverlayStyle,
  draggable: false,
  editable: false,
  clickable: false,
};
const satelliteOverlayStyle = {
  ...GOOGLE_MAP_ENUMS().satelliteOverlayStyle,
  draggable: false,
  editable: false,
  clickable: false,
};
const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
// const { mapOptions } = GOOGLE_MAP_ENUMS();
// const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;

const overlayStyle = (mapTypeId?: google.maps.MapTypeId) =>
  mapTypeId === google.maps.MapTypeId.ROADMAP || !mapTypeId ? roadmapOverlayStyle : satelliteOverlayStyle;

export const getMapTypeId = (mapTypeId?: google.maps.MapTypeId): google.maps.MapTypeId =>
  mapTypeId ?? google.maps.MapTypeId.ROADMAP;

export type MapsObject = google.maps.Circle | google.maps.Polygon | google.maps.Rectangle;

export const setMapShape = (questionMap: QuestionMapSchema, map: google.maps.Map): MapsObject => {
  const styles = overlayStyle(questionMap.mapTypeId);
  const shapes = {
    circle: new google.maps.Circle({
      map,
      center: new google.maps.LatLng(questionMap?.circle?.center ?? { lat: 0, lng: 0 }),
      radius: questionMap?.circle?.radius,
      ...styles,
    }),
    rectangle: new google.maps.Rectangle({
      map,
      bounds: new google.maps.LatLngBounds(questionMap.rectangle?.bounds.sw, questionMap.rectangle?.bounds.ne),
      ...styles,
    }),
    polygon: new google.maps.Polygon({
      map,
      paths: questionMap.polygon.path,
      ...styles,
    }),
  };
  return shapes[questionMap.shape];
};

export const useSyncRefs = <TType>(
  ...refs: (React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)[]
): ((value: TType) => void) => {
  const cache = useRef(refs);

  useEffect(() => {
    cache.current = refs;
  }, [refs]);

  return useCallback(
    (value: TType) => {
      cache.current.forEach((ref) => {
        if (ref == null) {
          return;
        }
        if (typeof ref === 'function') {
          // console.log('ref is a function. Returning called function');
          ref(value);
        } else {
          // console.log('returning the value: ', value);
          // eslint-disable-next-line no-param-reassign
          ref.current = value;
        }
      });
    },
    [cache],
  );
};

export const getPointFromGlobalLocation = (
  map: google.maps.Map | undefined,
  location: { x: number; y: number },
): google.maps.Point => {
  const mapTop = map?.getDiv().getBoundingClientRect().top ?? 0;
  const mapLeft = map?.getDiv().getBoundingClientRect().left ?? 0;
  const neBoundLatLng = map?.getBounds()?.getNorthEast();
  const swBoundLatLng = map?.getBounds()?.getSouthWest();

  const zoom = map?.getZoom() ?? 0;

  const scale = 2 ** zoom;

  const nw = map
    ?.getProjection()
    ?.fromLatLngToPoint(new google.maps.LatLng(neBoundLatLng?.lat() ?? 0, swBoundLatLng?.lng() ?? 0)) ?? { x: 0, y: 0 };

  const mapPointX = (location.x - mapLeft) / scale + nw.x;
  const mapPointY = (location.y - mapTop) / scale + nw.y;
  return new google.maps.Point(mapPointX, mapPointY);
};

export const isLocationInShape = (
  shape: MapsObject | undefined,
  location: google.maps.LatLngLiteral,
  shapeType: 'circle' | 'polygon' | 'rectangle',
): boolean => {
  switch (shapeType) {
    case shapeTypes.CIRCLE:
      return (
        google.maps.geometry.spherical.computeDistanceBetween(
          (shape as google.maps.Circle).getCenter() ?? location,
          location,
        ) <= (shape as google.maps.Circle).getRadius() ?? false
      );
    case shapeTypes.POLYGON:
      return google.maps.geometry.poly.containsLocation(location, shape as google.maps.Polygon);
    case shapeTypes.RECTANGLE:
      return shape?.getBounds()?.contains(location) ?? false;

    default:
      return false;
  }
};
